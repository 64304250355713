<template>
  <div
    class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:mt-12"
  >
    <div class="wise-iframe-wrapper">
      <iframe
        width="730"
        height="548"
        src="https://www.youtube.com/embed/aWOj3JPvy2s"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div
      class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-0"
    >
      <div class="relative">
        <div class="sm:text-center">
          <p class="mt-6 mx-auto max-w-2xl text-lg text-black">
            Refer a colleague and earn up to $2,000mxn.
          </p>
        </div>
      </div>
    </div>
    <div
      class="relative bg-white lg:col-start-7 lg:row-start-1 lg:my-8 lg:col-span-5 lg:grid lg:grid-cols-12 lg:items-center"
    >
      <div
        class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-2 lg:col-span-10 text-left"
      >
        <button
          class="block w-full py-2 px-16 font-light text-center bg-black hover:bg-gray-700 border border-transparent shadow-md text-base text-white sm:inline-block sm:w-auto tracking-wider"
          @click="showPopupReferAFriend"
          >Refer a colleague</button
        >
      </div>
    </div>
    <pop-up-refer
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-refer>
    <pop-up-thanks
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-thanks>
  </div>
</template>

<script>
import PopUpRefer from "../rewards/PopUpRefer.vue";
import PopUpThanks from "../rewards/PopUpThanks.vue";
export default {
  name: "Video",
  components: {
    PopUpRefer,
    PopUpThanks,
  },
  data: function() {
    return {
      popupDisplayed: 'None',
    }
  },
  methods: {
    showPopupReferAFriend() {
      this.popupDisplayed = 'Refer';
    },
  },
};
</script>

<style>
.wise-iframe-wrapper {
    position: relative;
    padding-bottom: 42.10%;
    margin: 0 auto;
    height: 0;
    overflow: hidden;
    max-width: 750px;
}
 
.wise-iframe-wrapper iframe,
.wise-iframe-wrapper object,
.wise-iframe-wrapper embed {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 750px;
    max-height: 500px;
}
</style>
