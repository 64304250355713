<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-if="popupDisplayed === 'Thanks'"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
      >
        <div class="bg-black px-4 py-8 pb-12">
          <div class="sm:flex sm:items-start">
            <span class="absolute right-4 text-white" @click="$emit('update:popupDisplayed', 'None')">
              <icon name="x-circle" class="w-6 h-6 cursor-pointer" @click="$emit('update:popupDisplayed', 'None')"/>
            </span>
            <div class="mt-12 text-center sm:text-left">
              <h3
                class="text-2xl leading-8 font-medium text-white w-3/4 mx-auto text-center"
                id="modal-title"
              >
                Thanks for referring a colleague!
              </h3>
              <div class="mt-8 text-center">
                <p class="text-base text-gray-300">
                  We recieve your's colleague information and we will
                </p>
                <p class="mt-4 text-base text-gray-300">
                  contact him/her as soon as possible!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PoUpSubscribed",
  components: {},
  props:{
    popupDisplayed: String,
  },
  data: function() {
    return {};
  },
  watch: {
    popupDisplayed(newValue) {
      this.$emit('update:popupDisplayed', newValue);
    }
  }
};
</script>
<style scoped></style>
