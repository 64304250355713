<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-4">
    <div
      class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:my-12"
    >
      <p
        class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl lg:my-8"
      >
        Still curious?
      </p>
    </div>
    <div
      class="flex max-w-7xl mx-auto sm:px-6 lg:px-0 my-4"
      v-for="question in questions"
      :key="question.id"
    >
      <div
        class="relative rounded-lg border border-gray-300 hover:bg-black hover:text-white cursor-pointer p-4 shadow-lg w-11/12"
        @click="toggleAnswer(question)"
      >
        <h1 class="flex font text-base">
          <span>{{ question.text }}</span>
          <span class="absolute right-4">
            <icon name="chevron-down" class="w-6 h-6" />
          </span>
        </h1>
        <collapse-transition group>
          <div
            class="ml-4"
            v-if="question.visible"
            :key="`show-${question.id}`"
          >
            <hr class="border-gray-300 mr-8 my-2">
            <h2 class="font-normal text-base" v-html="question.answer"></h2>
          </div>
        </collapse-transition>
      </div>
    </div>
    <div
      class="relative bg-white lg:col-start-7 lg:row-start-1 lg:my-8 lg:col-span-5 lg:grid lg:grid-cols-12 lg:items-center"
    >
      <div
        class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-2 lg:col-span-10 text-left"
      >
        
        <router-link
          class="block w-full py-2 px-16 font-light text-center bg-blue-600 bg-opacity-100 hover:bg-opacity-80 border border-transparent shadow-md text-base text-white sm:inline-block sm:w-auto tracking-wider"
          to="/jobs/all"
          >Get started</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "FrequentlyAskedQuestions",
  components: {
    CollapseTransition,
  },
  data: function() {
    return {
      questions: [
        {
          id: 1,
          text: "If I am interested in a position, how do I apply?",
          visible: false,
          answer:
            "You can apply through our page, clicking on the vacancy of your interest. You also have two options; send your written CV only, or add a video that tells us about your experience. We always recommend the second option if you want to stand out from the rest of the applicants.",
        },
        {
          id: 2,
          text:
            "How long should I wait to receive a response to my application?",
          visible: false,
          answer:
            "Your CV and Video-CV will be sent to one of our recruiters, so in the next 48 hours you should have a follow-up email to indicate the status of your application. Remember that you can always access the platform's chat to receive immediate attention.",
        },
        {
          id: 3,
          text: "How do I resolve my doubts about the process?",
          visible: false,
          answer:
            "Keeping us aware of the process is extremely important, that is why you have 3 alternatives to not lose track. <ul><li class='list-disc'> You can approach your recruiter directly for detailed feedback if you are already in direct contact with any of them.</li> <li class='list-disc'>In your Collok.io platform, you have the option to check the status of your process in your user's home page.</li><li class='list-disc'>Again, if you have an urgent question, you can write to us in the chat on this page, and one of our recruiters will answer your question.</li></ul>",
        },
        {
          id: 4,
          text: "How long does the process take?",
          visible: false,
          answer:
            "The average time is 3 weeks, although we will depend on how quickly we can schedule an interview with you and the corresponding manager, as well as the delivery of exercises and video interviews.",
        },
        {
          id: 5,
          text: "Can I apply for multiple vacancies at the same time?",
          visible: false,
          answer:
            "Yes, but remember to do it in those that you consider that you meet at least 80% of the profile. On the other hand, our recruiters will help you define if you are related to other positions and therefore apply for more than one.",
        },
      ],
    };
  },
  methods: {
    toggleAnswer(question) {
      this.closeAllQuestions(question.id);
      this.questions.find(
        (q) => q.id === question.id
      ).visible = !question.visible;
    },
    closeAllQuestions(questionId) {
      for (const question of this.questions) {
        if (questionId === question.id) {
          continue;
        }
        question.visible = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
