<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    v-if="popupDisplayed === 'GetInTouch'"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full"
      >
        <div class="bg-blue-600 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <span
            class="absolute right-4 text-white"
            @click="$emit('update:popupDisplayed', 'None')"
          >
            <icon name="x-circle" class="w-6 h-6 cursor-pointer" />
          </span>
          <form @submit.prevent="sendEmail">
            <div class="mt-12 text-center sm:text-left">
              <h3
                class="text-2xl leading-8 font-medium text-white w-3/4 mx-auto text-center"
                id="modal-title"
              >
                Thanks your being interested in joining our team!
              </h3>
              <div class="mt-8 text-center">
                <p class="text-base text-gray-300">
                  Let us get in touch with you when a vacancy that matches your
                  proﬁle gets available!
                </p>
                <div class="mt-2">
                  <input
                    placeholder="First Name"
                    name="first_name"
                    v-model="first_name"
                    autocomplete="given-name"
                    class="py-1 px-2 text-sm block w-full shadow-sm text-warm-gray-900 focus:teal-500 border-warm-gray-300"
                  />
                </div>
                <div class="mt-2">
                  <input
                    placeholder="Last Name"
                    name="last_name"
                    v-model="last_name"
                    autocomplete="family-name"
                    class="py-1 px-2 text-sm block w-full shadow-sm text-warm-gray-900 focus:teal-500 border-warm-gray-300"
                  />
                </div>
                <div class="mt-2">
                  <input
                    placeholder="Email Address"
                    name="email"
                    v-model="email"
                    autocomplete="email"
                    class="py-1 px-2 text-sm block w-full shadow-sm text-warm-gray-900 focus:teal-500 border-warm-gray-300"
                  />
                </div>
                <div class="mt-2">
                  <input
                    placeholder="Phone Number"
                    name="phone_number"
                    v-model="phone_number"
                    autocomplete="tel"
                    class="py-1 px-2 text-sm block w-full shadow-sm text-warm-gray-900 focus:teal-500 border-warm-gray-300"
                  />
                </div>
                <div class="mt-2">
                  <input
                    placeholder="Main Skillset"
                    name="main_skillset"
                    v-model="main_skillset"
                    class="py-1 px-2 text-sm block w-full shadow-sm text-warm-gray-900 focus:teal-500 border-warm-gray-300"
                  />
                </div>
                <div class="mt-2">
                  <input
                    placeholder="Years of experience"
                    name="years_of_experience"
                    v-model="years_of_experience"
                    type="number"
                    class="py-1 px-2 text-sm block w-full shadow-sm text-warm-gray-900 focus:teal-500 border-warm-gray-300"
                  />
                </div>
                <div class="mt-2" v-if="attachCV">
                  <div class="border border-dashed border-gray-200 relative">
                    <input
                      type="file"
                      name="resume"
                      class="cursor-pointer relative block opacity-0 w-full h-full p-20 z-50"
                    />
                    <div
                      class="text-center p-10 absolute top-0 right-0 left-0 m-auto text-white"
                    >
                      <h4>
                        Drop the file anywhere to upload CV
                        <br />or
                      </h4>
                      <p class="">Select File</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-blue-600 px-4 pt-8 pb-4 text-center">
                <button
                  type="submit"
                  class="justify-center px-10 py-2 border border-transparent text-lg font-light shadow-sm text-white bg-black bg-opacity-100 hover:bg-opacity-80 md:px-8 sm:px-4"
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "PopUpGetInTouch",
  components: {},
  props: {
    popupDisplayed: String,
  },
  data: function() {
    return {
      email: "",
      phone_number: "",
      first_name: "",
      last_name: "",
      main_skillset: "",
      years_of_experience: null,
      attachCV: true,
    };
  },
  computed: {
    validEmail() {
      if (!this.email) return false;
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.email.match(mailformat)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    sendEmail(e) {
      if (
        !this.validEmail ||
        !this.phone_number ||
        !this.first_name ||
        !this.main_skillset ||
        !this.years_of_experience
      ) {
        return;
      }
      try {
        emailjs.sendForm(
          "service_h50l5wa",
          "template_qvpm8lv",
          e.target,
          "user_lDFspKz5LrMJlgSlZhZgq",
          {
            email: this.email,
            phone_number: this.phone_number,
            first_name: this.first_name,
            last_name: this.last_name,
            main_skillset: this.main_skillset,
            years_of_experience: this.years_of_experience,
          }
        );
      } catch (error) {
        console.log({ error });
        return;
      }
      // Reset form field
      this.email = "";
      this.phone_number = "";
      this.first_name = "";
      this.last_name = "";
      this.main_skillset = "";
      this.years_of_experience = null;
      this.$emit("update:popupDisplayed", "Thanks");
    },
  },
  watch: {
    popupDisplayed(newValue) {
      this.$emit("update:popupDisplayed", newValue);
    },
  },
};
</script>
<style scoped></style>
