<template>
  <!-- Hero section -->
  <div class="relative">
    <div class="max-w-7xl mx-auto lg:px-0">
      <div class="relative shadow-xl sm:overflow-hidden">
        <div class="absolute inset-0 bg-blue-500">
          <img
            class="h-full w-full object-cover object-right bg-blue-800"
            src="/images/join_the_team.png"
            alt="Join us"
          />
          <div class="absolute inset-0" style="mix-blend-mode: multiply"></div>
        </div>
        <div class="lg:grid lg:grid-cols-12">
          <div
            class="relative px-4 py-16 sm:px-6 sm:py-24 lg:pt-36 lg:pb-32 lg:pl-16 lg:col-start-1 lg:row-start-1 lg:col-span-5"
          >
            <h6
              class="flex text-left font-light tracking-tight sm:text-5xl lg:text-lg text-white w-3/4"
            >
              <span class="block tracking-normal mr-1.5">{{
                numberOfJobs
              }}</span>
              <span class="block tracking-normal mr-1.5 text-blue-400"
                >jobs in</span
              >
              <span class="block tracking-normal mr-1.5">{{
                numberOfLocations
              }}</span>
              <span class="block tracking-normal mr-1.5 text-blue-400"
                >locations</span
              >
            </h6>
            <h1
              class="text-left font-bold tracking-tight sm:text-5xl lg:text-5xl text-white w-3/4"
            >
              <span class="block tracking-wide">Join the team</span>
            </h1>
            <div
              class="mt-4 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-left"
            >
              <router-link
                class="flex items-center justify-center px-10 py-2 border border-transparent text-lg font-light shadow-sm text-white bg-black bg-opacity-100 hover:bg-opacity-80 sm:px-8 tracking-wider"
                to="/jobs/all"
                >Explore all jobs</router-link
              >
            </div>
          </div>
          <template v-if="positions">
            <div class="relative lg:col-start-6 lg:row-start-1 lg:col-span-7 lg:hidden">
              <hooper
                :vertical="true"
                style="height: 100%"
                :items-to-show="1"
                :center-mode="true"
                :infinite-scroll="true"
                :auto-play="true"
                :play-speed="5000"
              >
                <slide v-for="position in positions" :key="position.key">
                  <div
                    class="rounded-lg border border-gray-300 bg-gray-100 p-4 shadow-lg w-5/6 cursor-pointer"
                    @click="showPopupInterested(position)"
                  >
                    <h6>{{ position.key }}.- {{ position.location }}</h6>
                    <h6>{{ position.position }}</h6>
                    <h6>{{ position.company }}</h6>
                  </div>
                </slide>
              </hooper>
            </div>
            <div class="relative lg:col-start-6 lg:row-start-1 lg:col-span-7 lg:block hidden">
              <hooper
                :vertical="true"
                style="height: 100%"
                :items-to-show="3"
                :center-mode="true"
                :infinite-scroll="true"
                :auto-play="true"
                :play-speed="5000"
              >
                <slide v-for="position in positions" :key="position.key">
                  <div
                    class="rounded-lg border border-gray-300 bg-gray-100 p-4 shadow-lg w-1/2 cursor-pointer"
                    :class="position.margin"
                    @click="showPopupInterested(position)"
                  >
                    <h6>{{ position.key }}.- {{ position.location }}</h6>
                    <h6>{{ position.position }}</h6>
                    <h6>{{ position.company }}</h6>
                  </div>
                </slide>
              </hooper>
            </div>
          </template>
        </div>
      </div>
    </div>
    <pop-up-interested
      :popupDisplayed.sync="popupDisplayed"
      :position="selectedPosition"
    ></pop-up-interested>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import PopUpInterested from "./PopUpInterested.vue";

export default {
  name: "JoinUs",
  components: {
    Hooper,
    Slide,
    PopUpInterested,
  },
  data: function() {
    return {
      popupDisplayed: "None",
      selectedPosition: null,
      positions: [],
      numberOfLocations: 0,
      numberOfJobs: 0,
      accessToken: "",
      tokenType: "",
    };
  },
  methods: {
    showPopupInterested(position) {
      this.popupDisplayed = "Interested";
      this.selectedPosition = position;
    },
  },
  mounted: function() {
    const axios = require("axios");
    const data = JSON.stringify({
      grant_type: "client_credentials",
      client_id: 2,
      client_secret: "uy6dbmfPmSqooF85psjSPyKq3mZLAC5HsAxD9h8H",
      scope: "",
    });
    const config = {
      method: "post",
      url: "https://screenit.collok.io/api/oauth/token",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.accessToken = response.data.access_token;
        this.tokenType = response.data.token_type;
        const petitionConfig = {
          method: "get",
          url: "https://screenit.collok.io/api/api-positions/",
          headers: {
            Accept: "application/json",
            Authorization: `${this.tokenType} ${this.accessToken}`,
          },
        };
        axios(petitionConfig)
          .then((response) => {
            const locations = response.data.data.reverse().reduce(function(
              allStates,
              state
            ) {
              if (state.state.name in allStates) {
                allStates[state.state.name]++;
              } else {
                allStates[state.state.name] = 1;
              }
              return allStates;
            },
            {});
            this.numberOfLocations = Object.entries(locations).length;
            this.numberOfJobs = response.data.data.length;
            this.positions = response.data.data
              .filter((_, index) => index < 9)
              .map((pos, ind) => {
                let margin = "ml-0";
                if (ind === 0 || ind === 3 || ind === 6) {
                  margin = "ml-0";
                } else if (ind === 1 || ind === 4 || ind === 7) {
                  margin = "ml-36";
                } else if (ind === 2 || ind === 5 || ind === 8) {
                  margin = "ml-80";
                }
                return {
                  key: pos.id,
                  position: pos.name,
                  location: pos.state.name,
                  company: "",
                  margin: margin,
                };
              });
          })
          .catch(function(error) {
            console.error(error);
          });
      })
      .catch(function(error) {
        console.error(error);
      });
  },
};
</script>

<style scoped></style>
