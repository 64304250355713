<template>
  <div class="bg-black max-w-7xl mx-auto sm:px-6 lg:px-0">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-20">
      <div class="space-y-12">
        <div
          v-if="text.title"
          class="space-y-5 sm:space-y-4 text-center"
        >
          <p class="font-bold text-white tracking-tight text-4xl">
            {{ text.title }}
          </p>
        </div>
        <div
          class="space-y-5 sm:space-y-4 text-center"
        >
          <p
            class="font-normal text-white tracking-tight"
            :class="{ 'text-3xl': !text.title, 'text-2xl': text.title }"
            v-html="text.description"
          >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "BlackBanner1",
  components: {
  },
  props: {
    text: Object,
  },
};
</script>

<style scoped></style>
