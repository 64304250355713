<template>
  <div class="bg-white">
    <main>
      <join-us></join-us>
      <unleash-your-potential></unleash-your-potential>
      <black-banner :text="text"></black-banner>
      <on-demand-interview></on-demand-interview>
      <frequently-asked-questions></frequently-asked-questions>
      <black-banner :text="text2"></black-banner>
      <refer-a-friend></refer-a-friend>
      <send-your-info></send-your-info>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import JoinUs from '@/components/jobs/JoinUs.vue';
import UnleashYourPotential from '@/components/jobs/UnleashYourPotential.vue';
import BlackBanner from '@/components/BlackBanner.vue';
import OnDemandInterview from '@/components/jobs/OnDemandInterview.vue';
import FrequentlyAskedQuestions from '../components/jobs/FrequentlyAskedQuestions.vue';
import ReferAFriend from '../components/jobs/ReferAFriend.vue';
import SendYourInfo from '../components/jobs/SendYourInfo.vue';

export default {
  name: 'Jobs',
  components: {
    JoinUs,
    UnleashYourPotential,
    BlackBanner,
    OnDemandInterview,
    FrequentlyAskedQuestions,
    ReferAFriend,
    SendYourInfo,
  },
  data: function () {
    return {
      text: {
        title: null,
        description: `Don't just work here. Belong here. <br />
            You are welcome at ScreenIT for who you are, <br />
            no matter where you come from or what you look like.`
      },
      text2: {
        title: 'Earn cash by referring colleagues!',
        description: `We are looking for the best IT talent! If you know somebody  <br />
            with an IT proﬁle you can win a reward!.`
      },
    }
  }
}
</script>
