<template>
  <div class="relative py-16 bg-white">
    <div
      class="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
      aria-hidden="true"
    ></div>
    <div class="max-w-7xl mx-auto bg-white lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="relative z-9 lg:col-start-6 lg:row-start-1 lg:col-span-5 lg:bg-transparent"
        >
          <div
            class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
            aria-hidden="true"
          ></div>
          <div
            class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0"
          >
            <div
              class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-3 lg:aspect-h-2"
            >
              <img
                class="object-cover object-center shadow-2xl"
                src="/images/unleash_your_potential_final.jpg"
                alt="Unleash your potential"
              />
            </div>
          </div>
        </div>

        <div
          class="relative bg-white lg:col-start-3 lg:row-start-1 lg:my-8 lg:col-span-4 lg:grid lg:grid-cols-12 lg:items-center"
        >
          <div
            class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-2 lg:col-span-10 text-left"
          >
            <h2 class="text-2xl font-extrabold text-black" id="join-heading">
              Unleash your potential
            </h2>
            <p class="text-lg text-black">
              It’s our culture. It’s our values. It’s who we are and what we’re
              not. It’s why we do things the way we do and why that matters.
              It’s all here in our manifesto.
            </p>
            <button
              @click.prevent="popupDisplayed = 'Video'"
              class="block font-light py-3 px-8 text-center bg-black border border-transparent shadow-md text-base text-white hover:bg-gray-700 sm:inline-block sm:w-auto tracking-wider"
            >
              See more
            </button>
          </div>
        </div>
      </div>
    </div>
    <pop-up-video
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-video>
  </div>
</template>

<script>
import PopUpVideo from "./PopUpVideo.vue";

export default {
  name: "UnleashYourPotential",
  components: {
    PopUpVideo,
  },
  data: function() {
    return {
      popupDisplayed: 'None',
    };
  },
};
</script>

<style scoped></style>
