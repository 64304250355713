<template>
  <div class="bg-white py-16 sm:py-0 max-w-7xl mx-auto lg:px-0">
    <div class="relative sm:py-16">
      <div aria-hidden="true">
        <div class="absolute inset-0">
          <img
            class="h-full w-full object-cover"
            src="/images/send_your_info.jpg"
            alt="Send your info"
          />
          <div class="absolute inset-0" style="mix-blend-mode: multiply"></div>
        </div>
      </div>
      <div
        class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-0"
      >
        <div class="relative px-6 py-10 overflow-hidden sm:px-12 sm:py-20">
          <div class="relative">
            <div class="sm:text-center">
              <h2
                class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl"
              >
                Didn’t ﬁnd what you’re looking for?
              </h2>
              <p class="mt-6 mx-auto max-w-2xl text-lg text-indigo-200">
                Say hi to our team and send us your contact details for future
                positions!
              </p>
            </div>
            <div class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
              <div class="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="button"
                  @click="showPopupGetInTouch"
                  class="block w-full px-5 py-3 bg-blue-600 text-base font-light text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10 tracking-wider"
                >
                  Send us your info
                </button>
              </div>
              <div class="mt-4 sm:mt-0 sm:ml-3">
                <button
                  @click="showPopupInterested"
                  class="block w-full px-5 py-3 bg-blue-600 text-base font-light text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10 tracking-wider"
                >
                  Start your video CV
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pop-up-get-in-touch
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-get-in-touch>
    <pop-up-interested
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-interested>
    <pop-up-thanks
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-thanks>
  </div>
</template>

<script>
import PopUpGetInTouch from "./PopUpGetInTouch.vue";
import PopUpInterested from "./PopUpInterested.vue";
import PopUpThanks from "./PopUpThanks.vue";
export default {
  name: "SendYourInfo",
  components: {
    PopUpGetInTouch,
    PopUpInterested,
    PopUpThanks,
  },
  data: function() {
    return {
      popupDisplayed: "None",
    };
  },
  methods: {
    showPopupGetInTouch() {
      this.popupDisplayed = "GetInTouch";
    },
    showPopupInterested() {
      this.popupDisplayed = "Interested";
    },
  },
};
</script>

<style scoped></style>
